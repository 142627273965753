import React from "react";

import { AppLink } from "@base";
import { SiteNav } from "@molecules";
import { Icon, Container, Button } from "@atoms";
import { useAppState } from "@state";
import useLgcaAcHeaderData from "@staticQueries/lgcaAc/HeaderQuery";
import classNames from "classnames";

const LgcaAcHeader = () => {
  const data = useLgcaAcHeaderData();

  const [{ layout }, dispatch] = useAppState();
  const { theme, hideNav, modalOpen } = layout;
  const colors = {
    dark: {
      text: "text-white",
    },
    light: {
      text: "text-black",
    },
  };

  return (
    <>
      <SiteNav data={data} />
      <header
        className={classNames(
          "absolute left-0 right-0 top-0 z-40 w-full sm:z-[100]",
          {
            "pointer-events-none opacity-0": modalOpen,
          }
        )}
      >
        <Container>
          <div className="-mx-3 flex h-24 items-center sm:-mx-6">
            <div className="px-3 sm:px-6">
              <AppLink
                className="relative block translate-y-1 transform text-white"
                to="/"
              >
                <Icon
                  name="lgcaLogo"
                  className={`${
                    theme === "dark" ? "text-white" : "text-orange"
                  } h-20 w-20 sm:h-24 sm:w-24`}
                />
              </AppLink>
            </div>
            <div
              className={classNames(
                colors[theme].text,
                "ml-auto flex items-center px-3 text-sm font-bold uppercase transition duration-200 sm:px-6",
                {
                  "pointer-events-none opacity-0": hideNav,
                }
              )}
            >
              <div className="mx-4 hidden sm:block">
                <Button size="sm" to="/action/donate">
                  Donate
                </Button>
              </div>
              <div className="ml-4">
                <button
                  type="button"
                  className="inline-flex items-center text-xs"
                  onClick={() => {
                    dispatch({ type: "toggleNav" });
                  }}
                >
                  <Icon name="bars" className="ml-2 h-8 w-8" />
                </button>
              </div>
            </div>
            {/* <div className="px-3 sm:px-6 hidden">mobile nav button</div> */}
          </div>
        </Container>
      </header>
    </>
  );
};

LgcaAcHeader.defaultProps = {};

export default LgcaAcHeader;
